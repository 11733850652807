import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import I18NextChainedBackend from "i18next-chained-backend";
import I18NextLocalStorageBackend from "i18next-localstorage-backend";

const instance = i18next
  .use(I18NextChainedBackend)
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next);

instance.init({
  ns: [
    "common",
    "line_sheet_sets",
    "order_sheet_sets",
    "auth",
    "order_confirmation_invoices",
    "proforma_invoices",
    "bank_remittance_receipts",
    "deposit_invoices",
    "credit_invoices",
    "landing",
    "countries",
  ],
  fallbackLng: "en",
  backend: {
    backends: [I18NextLocalStorageBackend, I18NextHttpBackend],
    backendOptions: [
      {
        defaultVersion: "1737012677",
      },
      {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
      },
    ],
  },
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
});
