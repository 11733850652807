import AppCompanySelect from "@/features/line-sheet-sets/app-company-select";
import { Box, Flex } from "@chakra-ui/react";
import AppDashboardCard from "@/features/dashboard/app-dashboard-card";
import useDashboardPagePersistedQuery from "@/features/dashboard/hooks/use-dashboard-page-persisted-query";
import useIdentity from "@/features/ui/hooks/use-identity";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import React, { useEffect, useMemo } from "react";
import {
  ListInvoiceStatisticsByTypeRequest,
  useListInvoiceStatisticsByType,
} from "@/features/dashboard/hooks/use-list-invoice-statistics-by-type";
import AppTable, { AppTableCell } from "@/features/line-sheet-sets/app-table";
import { InvoiceStatisticByType } from "@/features/dashboard/dashboard.type";
import AppButton from "@/features/line-sheet-sets/app-button";
import { CURRENT_SEASON } from "@/features/line-sheet-sets/app-season-select";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import { toTitle, toUpper } from "@/utils/case";
import AppLink from "@/features/dashboard/app-link";
import AppSeasonMultiSelect from "@/features/line-sheet-sets/app-season-multi-select";
import { PersistedCreditInvoiceListPageQuery } from "@/features/invoices/credit-invoices/hooks/use-persisted-credit-invoice-list-query";

import { PersistedOrderConfirmationInvoiceListPageQuery } from "@/features/invoices/order-confirmation-invoices/order-confirmation-invoice-list-page";

import { PersistedProformaInvoicesListPageQuery } from "@/features/invoices/proforma-invoices/proforma-invoice-list-page";

export default function InvoiceStatisticCard() {
  const { value: queryValue, set: setQuery } = useDashboardPagePersistedQuery();
  const identity = useIdentity();
  const { error: showError } = useAppToasts();
  const { t, tTitle, tCurrencyString } = useI18nHelper();
  const listInvoiceStatisticsByTypeKey = useMemo(() => {
    if (identity?.company?.type === "BUYER") {
      const request: ListInvoiceStatisticsByTypeRequest = {
        season__in: queryValue.invoiceStatisticSeason__in,
      };

      if (queryValue["invoiceStatisticIssuedByCompany.id__in"]) {
        request["issuedByCompany.id__in"] =
          queryValue["invoiceStatisticIssuedByCompany.id__in"];
      }

      if (queryValue["invoiceStatisticMadeByCompany.id__eq"]) {
        request["madeByCompany.id__eq"] =
          queryValue["invoiceStatisticMadeByCompany.id__eq"];
      }

      return {
        parameter: {
          by: identity.company.type,
          companyId: identity.company.id,
        },
        request: request,
      };
    }
  }, [identity, queryValue]);

  const {
    isLoading: isListInvoiceStatisticsByTypeLoading,
    data: listInvoiceStatisticsByTypeData,
    error: listInvoiceStatisticsByTypeError,
  } = useListInvoiceStatisticsByType(
    listInvoiceStatisticsByTypeKey?.parameter,
    listInvoiceStatisticsByTypeKey?.request,
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (listInvoiceStatisticsByTypeError) {
      showError(listInvoiceStatisticsByTypeError);
    }
  }, [showError, listInvoiceStatisticsByTypeError]);

  const table = useMemo(() => {
    const rows = listInvoiceStatisticsByTypeData?.invoiceStatisticsByType
      .map((item): AppTableCell<InvoiceStatisticByType>[] => {
        const readableType = item.type.replace("_", " ");
        let links: JSX.Element[] = [];

        switch (item.type) {
          case "ORDER_CONFIRMATION":
            links = [
              <AppLink<PersistedOrderConfirmationInvoiceListPageQuery>
                key={"abbr"}
                title={toUpper(t("order_confirmation_invoice.abbr"))}
                pathname={"/order-confirmation-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  "orderConfirmationInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                }}
              />,
              <AppLink<PersistedOrderConfirmationInvoiceListPageQuery>
                key={"total_amount"}
                title={item.totalAmount}
                pathname={"/order-confirmation-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  "orderConfirmationInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                }}
              />,
              <AppLink<PersistedOrderConfirmationInvoiceListPageQuery>
                key={"unremitted_amount"}
                title={item.unremittedAmount}
                pathname={"/order-confirmation-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  "orderConfirmationInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                  // isRemitted__eq: false,
                }}
              />,
              <AppLink<PersistedOrderConfirmationInvoiceListPageQuery>
                key={"total_amount"}
                title={item.remittedAmount}
                pathname={"/order-confirmation-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  "orderConfirmationInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                  // isRemitted__eq: true,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"earning_amount"}
                title={item.earningAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  type__eq: "SEASON",
                }}
              />,
            ];
            break;
          case "SEASON_PROFORMA":
            links = [
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"abbr"}
                title={toUpper(
                  `[${t("pre_order")}] ${t("proforma_invoice.abbr")}`
                )}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "SEASON",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                }}
              />,
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"total_amount"}
                title={item.totalAmount}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "SEASON",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                }}
              />,
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"unremitted_amount"}
                title={item.unremittedAmount}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "SEASON",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                  // isRemitted__eq: false,
                }}
              />,
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"remitted_amount"}
                title={item.remittedAmount}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "SEASON",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                  // isRemitted__eq: true,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"earning_amount"}
                title={item.earningAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  type__eq: "FIXING",
                }}
              />,
            ];
            break;
          case "STOCK_PROFORMA":
            links = [
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"abbr"}
                title={toUpper(`[${t("stock")}] ${t("proforma_invoice.abbr")}`)}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "STOCK",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                }}
              />,
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"total_amount"}
                title={item.totalAmount}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "STOCK",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                }}
              />,
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"unremitted_amount"}
                title={item.unremittedAmount}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "STOCK",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                  // isRemitted__eq: false,
                }}
              />,
              <AppLink<PersistedProformaInvoicesListPageQuery>
                key={"remitted_amount"}
                title={item.remittedAmount}
                pathname={"/proforma-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  lineSheetSetType__eq: "STOCK",
                  "proformaInvoiceDetailList.brand.id__eq":
                    queryValue["invoiceStatisticMadeByCompany.id__eq"],
                  // isRemitted__eq: true,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"earning_amount"}
                title={item.earningAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                  type__eq: "FIXING",
                }}
              />,
            ];
            break;
          case "CREDIT":
            links = [
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"abbr"}
                title={toUpper(t("credit_invoice.abbr"))}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"total_amount"}
                title={item.totalAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"unremitted_amount"}
                title={item.unremittedAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"remitted_amount"}
                title={item.remittedAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                }}
              />,
              <AppLink<PersistedCreditInvoiceListPageQuery>
                key={"earning_amount"}
                title={item.earningAmount}
                pathname={"/credit-invoices"}
                query={{
                  pageNumber: 1,
                  "issuedByCompany.id__in":
                    queryValue["invoiceStatisticIssuedByCompany.id__in"],
                  season__in: queryValue.invoiceStatisticSeason__in,
                }}
              />,
            ];
        }

        return [
          { value: "" },
          ...links.map((link, index) => {
            const isPrice = index !== 0;
            return {
              value: (
                <Flex
                  flexDirection={"row"}
                  justifyContent={isPrice ? "end" : "start"}
                >
                  {link}
                </Flex>
              ),
            };
          }),
          { value: "" },
        ];
      })
      .filter((row) => row.length !== 2);

    return (
      <React.Fragment>
        <AppTable<InvoiceStatisticByType>
          headers={[
            {
              value: "",
              width: "24px",
            },
            {
              value: "",
              width: "1fr",
            },
            {
              value: tTitle("total"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
              marginRight: "16px",
            },
            {
              value: tTitle("remittance_required_amount.abbr"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
              marginRight: "16px",
            },
            {
              value: tTitle("common:remitted_amount.abbr"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
              marginRight: "16px",
            },
            {
              value: tTitle("issued_amount.abbr"),
              width: "1fr",
              color: "#6F6F6C",
              textAlign: "end",
              marginRight: "16px",
            },
            {
              value: "",
              width: "24px",
            },
          ]}
          isLoading={listInvoiceStatisticsByTypeData === undefined}
          rows={rows}
          gap={"80px"}
          rowHeight={"32px"}
        />
        <Box height={"24px"}></Box>
      </React.Fragment>
    );
  }, [listInvoiceStatisticsByTypeData, queryValue, t, tTitle]);

  return (
    <AppDashboardCard
      title={tTitle("invoice")}
      filters={[
        <AppCompanySelect
          key={"InvoiceStatusFilter_IssuedBy"}
          name={tTitle("issued_by")}
          types={["BOUTIQUE"]}
          isMultiple={true}
          id={queryValue["invoiceStatisticIssuedByCompany.id__in"]}
          onSelect={(value) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  "invoiceStatisticIssuedByCompany.id__in": value?.map(
                    (item) => item.id
                  ),
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,
        <AppCompanySelect
          key={"InvoiceStatusFilter_Brand"}
          name={toTitle(t("brand"))}
          types={["BRAND"]}
          id={queryValue["invoiceStatisticMadeByCompany.id__eq"]}
          onSelect={(company) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  "invoiceStatisticMadeByCompany.id__eq": company?.id,
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,
        <AppSeasonMultiSelect
          key={"InvoiceStatusFilter_Season"}
          value={queryValue.invoiceStatisticSeason__in}
          onSelect={(value) => {
            setQuery(
              (prev) => {
                return {
                  ...prev,
                  invoiceStatisticSeason__in: value,
                };
              },
              {
                replace: true,
              }
            );
          }}
        />,
        <AppButton
          key={"InvoiceStatusFilter_Reset"}
          variant={"normal"}
          size={"medium"}
          width={"144px"}
          onClick={() => {
            setQuery((prev) => {
              return {
                ...prev,
                "invoiceStatisticMadeByCompany.id__eq": undefined,
                "invoiceStatisticIssuedByCompany.id__in": undefined,
                invoiceStatisticSeason__in: [CURRENT_SEASON],
              };
            });
          }}
        >
          {tTitle("reset_filter")}
        </AppButton>,
      ]}
      totalStatistics={[
        {
          name: tTitle("common:total_remittance_required_amount.abbr"),
          value: tCurrencyString(
            listInvoiceStatisticsByTypeData?.totalInvoiceStatistic
              .unremittedAmount || 0
          ),
        },
        {
          name: tTitle("common:total_remitted_amount.abbr"),
          value: tCurrencyString(
            listInvoiceStatisticsByTypeData?.totalInvoiceStatistic
              .remittedAmount || 0
          ),
        },
      ]}
    >
      {table}
    </AppDashboardCard>
  );
}
