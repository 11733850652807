import { useMemo } from "react";
import * as Yup from "yup";
import {
  AttachmentFormField,
  isLineSheetFormField,
  LineSheetFormField,
  LineSheetSetFormFields,
} from "@/features/line-sheet-sets/detail/line-sheet-set-detail-page";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import useYupHelper from "@/features/ui/form/use-yup-helper";

export default function useLineSheetSetFormValidationSchema() {
  const { t, tTitle } = useI18nHelper();
  const { withMessage, formT } = useYupHelper({
    ns: "line_sheet_sets",
    prefix: "upload_line_sheet_sets_form",
  });

  return useMemo(() => {
    return Yup.object({
      name: Yup.string().required(withMessage.required),
      type: Yup.string().required(withMessage.required),
      season: Yup.string().when("type", (values, schema) => {
        const [type] = values;
        if (type === "SEASON") {
          return schema.required(withMessage.required);
        }
        return schema.notRequired();
      }),
      issuedByBoutique: Yup.object().required(withMessage.required),
      issuedByBrand: Yup.object().when("type", (values, schema) => {
        const [type] = values;
        if (type === "STOCK") {
          return schema.notRequired();
        }
        return schema.required(withMessage.required);
      }),
      lineSheetOrAttachmentList: Yup.array()
        .of(Yup.object({}))
        .test(
          "EmptyOrderFormOrLineSheet",
          t(
            "line_sheet_sets:upload_line_sheet_sets_form.messages.error.empty_order_form_or_line_sheet"
          ),
          (value, context) => {
            const lineSheetOrAttachmentList = value as (
              | LineSheetFormField
              | AttachmentFormField
            )[];

            const { externalFileLinks } =
              context.parent as LineSheetSetFormFields;

            if ((externalFileLinks || []).length === 0) {
              return (
                lineSheetOrAttachmentList.filter(isLineSheetFormField).length >
                0
              );
            }

            return true;
          }
        ),
      validUntil: Yup.date()
        .required(withMessage.required)
        .test(
          "invalid_valid_until",
          formT("messages.error.invalid_valid_until"),
          (value, context) => {
            return new Date().getTime() < value.getTime();
          }
        ),
      externalFileLinks: Yup.array()
        .test(
          "external_link_format",
          formT("messages.error.invalid_external_link_format"),
          (value, context) => {
            const links = (value || []) as string[];
            return links.every((link) => {
              return link.startsWith("https://");
            });
          }
        )
        .test(
          "external_link_format",
          formT("messages.error.not_support_external_link"),
          (value, context) => {
            const links = (value || []) as string[];
            return links.every((link) => {
              return (
                link.includes("drive.google.com") ||
                link.includes("dropbox.com") ||
                link.includes("we.tl") ||
                link.includes("wetransfer.com") ||
                link.includes("swisstransfer")
              );
            });
          }
        ),
    });
  }, [t, formT, withMessage.required]);
}
