import AppLabel from "@/features/ui/app-label";
import { OrderSheetStatus } from "@/features/order-sheets/order-sheet.type";
import { toLower } from "@/utils/case";
import useIdentity, { Identity } from "@/features/ui/hooks/use-identity";
import { useMemo } from "react";
import { Box } from "@chakra-ui/react";

interface AppOrderSheetStatusLabelProps {
  value: OrderSheetStatus;
}

export function getVisibleOrderSheetStatusLabel({
  status,
  identity,
}: {
  status: OrderSheetStatus;
  identity: Identity | null;
}) {
  const isBuyer = identity?.company?.type === "BUYER";

  if (isBuyer) {
    if (status === "INITIAL_DRAFT") {
      return "";
    } else if (["ORDERED_TO_AGENCY"].includes(status)) {
      return "ORDERED_TO_AGENCY";
    } else {
      return status;
    }
  } else {
    return status;
  }
}

export default function AppOrderSheetStatusLabel({
  value,
}: AppOrderSheetStatusLabelProps) {
  const identity = useIdentity();
  const visibleValue = getVisibleOrderSheetStatusLabel({
    status: value,
    identity: identity,
  });

  if (!visibleValue) {
    return <Box></Box>;
  }

  const level = [
    "ORDERED_TO_AGENCY",
    "AGENCY_CONFIRMED",
    "ORDERED_TO_BOUTIQUE",
    "BOUTIQUE_CONFIRMED",
  ].includes(visibleValue)
    ? "SUCCESS"
    : "LIGHT";

  return (
    <AppLabel
      nameKey={`order_sheet.status.${toLower(visibleValue)}`}
      level={level}
    />
  );
}
