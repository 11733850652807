import useFetcher from "@/utils/use-fetcher";
import {
  getGetLineSheetTabKey,
  GetLineSheetTabRequest,
  GetLineSheetTabResponse,
} from "@/features/line-sheet-sets/hooks/use-get-line-sheet-tab";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import { SheetProduct, SheetTab } from "@/features/order-sheets/sheet.type";
import {
  getOrderSheetRevisionKey,
  GetOrderSheetRevisionRequest,
  parseGetOrderSheetRevisionKey,
} from "./use-get-order-sheet-revision";

export interface GetListAllSheetTabsWithRevisionResponse {
  sheetTabs: SheetTab[];
  _orderSheetSetId: number;
  _orderSheetId: number;
}
export interface GetListOrderSheetTabRevisionResponse {
  createdAt: Date;
  createdBy: {
    id: number;
    name: string;
  };
  createdByCompany: {
    id: number;
    name: string;
  };
  orderSheetTabs: SheetTab[];
  revisionNumber: number;
}

export default function useListAllSheetTabsAndRevisionFetcher() {
  const getLineSheetTabFetcher = useFetcher<
    GetLineSheetTabRequest,
    GetLineSheetTabResponse
  >();
  const getOrderSheetRevisionFetcher = useFetcher<
    GetOrderSheetRevisionRequest,
    GetListOrderSheetTabRevisionResponse
  >();

  return useMemo(() => {
    return async (
      config: AxiosRequestConfig<GetOrderSheetRevisionRequest>
    ): Promise<GetListAllSheetTabsWithRevisionResponse> => {
      const { parameter, request } = parseGetOrderSheetRevisionKey(config);
      if (
        !parameter.companyId ||
        parameter.companyId < 0 ||
        !parameter.orderSheetId ||
        parameter.orderSheetId < 0 ||
        !parameter.orderSheetSetId ||
        parameter.orderSheetSetId < 0 ||
        !parameter.revisionNumber ||
        parameter.revisionNumber < 0
      ) {
        const sheetTabs: SheetTab[] = [];
        return Promise.resolve({
          _orderSheetSetId: parameter.orderSheetSetId,
          _orderSheetId: parameter.orderSheetId,
          sheetTabs,
        });
      }

      const lineSheetTabResponses = await Promise.all(
        request.sheetTabIndexes.flatMap((tabIndex) => {
          return [
            getLineSheetTabFetcher(
              getGetLineSheetTabKey({
                by: parameter.by,
                companyId: parameter.companyId,
                lineSheetSetId: request.lineSheetSetId,
                lineSheetId: request.lineSheetId,
                lineSheetTabIndex: tabIndex,
              })!!
            ),
          ];
        })
      );
      const orderSheetTabsResponse = await Promise.resolve(
        getOrderSheetRevisionFetcher(
          getOrderSheetRevisionKey(
            {
              by: parameter.by,
              companyId: parameter.companyId,
              orderSheetSetId: parameter.orderSheetSetId,
              orderSheetId: parameter.orderSheetId,
              revisionNumber: parameter.revisionNumber,
            },
            request
          )
        )
      );

      const sheetTabs = [];

      for (let i = 0; i < lineSheetTabResponses.length; i += 1) {
        const lineSheetTab = lineSheetTabResponses[
          i
        ] as GetLineSheetTabResponse;
        const orderSheetTab =
          orderSheetTabsResponse.orderSheetTabs[lineSheetTab.index];

        const sheetProducts = lineSheetTab.products.map(
          (lineSheetProduct, index): SheetProduct => {
            const orderSheetProduct = orderSheetTab.products[index];
            if (orderSheetProduct) {
              return {
                ...lineSheetProduct,
                supplyPrice: orderSheetProduct.supplyPrice,
                priceAdjustedBy: orderSheetProduct.priceAdjustedBy,
                orderQuantityWithOptionList:
                  orderSheetProduct.orderQuantityWithOptionList,
                confirmedPriceAdjustedBy:
                  orderSheetProduct.confirmedPriceAdjustedBy,
                confirmedPrice: orderSheetProduct.confirmedPrice,
                newStockWithOptionList:
                  orderSheetProduct.newStockWithOptionList,
                confirmedQuantityWithOptionList:
                  orderSheetProduct.confirmedQuantityWithOptionList,
                firstOrderQuantityWithOptionList:
                  orderSheetProduct.firstOrderQuantityWithOptionList,
                prevOrderQuantityWithOptionList:
                  orderSheetProduct.prevOrderQuantityWithOptionList,
                latestOrderQuantityWithOptionList:
                  orderSheetProduct.latestOrderQuantityWithOptionList,
              };
            } else {
              return {
                ...lineSheetProduct,
                orderQuantityWithOptionList: [],
                confirmedPriceAdjustedBy: null,
                confirmedPrice: null,
                newStockWithOptionList: null,
                confirmedQuantityWithOptionList: null,
                firstOrderQuantityWithOptionList: null,
                prevOrderQuantityWithOptionList: null,
                latestOrderQuantityWithOptionList: null,
              };
            }
          }
        );

        sheetTabs.push({
          index: lineSheetTab.index,
          name: lineSheetTab.name,
          products: sheetProducts,
        });
      }

      return Promise.resolve({
        _orderSheetSetId: parameter.orderSheetSetId,
        _orderSheetId: parameter.orderSheetId,
        sheetTabs,
      });
    };
  }, [getOrderSheetRevisionFetcher, getLineSheetTabFetcher]);
}
