import { useRecoilCallback } from "recoil";
import { SheetProduct } from "@/features/order-sheets/sheet.type";
import {
  FlatSheetKey,
  InflatedProduct,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { produce } from "immer";
import { ProductPrice } from "@/features/types";
import { PriceAdjustedBy } from "@/features/line-sheet-sets/line-sheet-set.types";

function calcSupply(product: SheetProduct): {
  confirmedPrice: ProductPrice | null;
  supplyPrice: ProductPrice | null;
} {
  let confirmedPrice = null;
  let supplyPrice = null;
  if (product.confirmedPriceAdjustedBy) {
    confirmedPrice = calcPrice(
      product.confirmedPriceAdjustedBy,
      product.costPrice,
      product.retailPrice,
      product.supplyPrice
    );
  }

  if (product.priceAdjustedBy) {
    supplyPrice = calcPrice(
      product.priceAdjustedBy,
      product.costPrice,
      product.retailPrice,
      product.supplyPrice
    );
  }

  return {
    confirmedPrice,
    supplyPrice,
  };
}

function calcPrice(
  condition: PriceAdjustedBy | null,
  costPrice: ProductPrice | null,
  retailPrice: ProductPrice | null,
  previousSupplyPrice: ProductPrice | null
): ProductPrice | null {
  if (condition) {
    const { by, value } = condition;
    let supplyPrice;
    let iva = 1.22;
    if (value === 0) {
      iva = 1;
    }
    if (!costPrice && !retailPrice && previousSupplyPrice) {
      // cost와 retial이 없는데(0인데) supply price가 주어져 있다면, supply price를 수정하지 않는다.
      supplyPrice = {
        amount: previousSupplyPrice.amount,
        currency: previousSupplyPrice.currency,
      };
    } else if (by === "MARKUP" && costPrice) {
      supplyPrice = {
        amount: Math.round(costPrice.amount * (1 + value / 100) * 1000) / 1000,
        currency: costPrice.currency,
      };
    } else if (
      by === "DISCOUNT" &&
      retailPrice &&
      retailPrice.currency === "KRW"
    ) {
      // 원화인 경우 국내 재고이기 때문에 IVA를 제외하지 않는다.
      supplyPrice = {
        amount:
          Math.round(retailPrice.amount * (1 - value / 100) * 1000) / 1000,
        currency: retailPrice.currency,
      };
    } else if (by === "DISCOUNT" && retailPrice) {
      supplyPrice = {
        amount:
          Math.round(((retailPrice.amount * (1 - value / 100)) / iva) * 1000) /
          1000,
        currency: retailPrice.currency,
      };
    } else {
      supplyPrice = null;
    }
    return supplyPrice;
  }
  return null;
}

export default function useInflatedTabs() {
  const updateInflatedProduct = useRecoilCallback(
    ({ set }) =>
      (key: FlatSheetKey, tabIndex: number, nextProduct: InflatedProduct) => {
        set(inflatedTabsFamily(key), (prevState) => {
          if (prevState && tabIndex > -1) {
            return produce(prevState, (draft) => {
              const draftTabIndex = draft.tabs.findIndex(
                (tab) => tab.index === tabIndex
              );

              if (draftTabIndex > -1) {
                const productIndex = draft.tabs[
                  draftTabIndex
                ].products.findIndex(
                  (product) => product.id === nextProduct.id
                );

                if (productIndex > -1) {
                  draft.tabs[draftTabIndex].products[productIndex] = {
                    ...nextProduct,
                    ...calcSupply(nextProduct),
                  };
                }
                draft.tabs[draftTabIndex].isDirty = true;
              }
            });
          }

          return prevState;
        });
      },
    []
  );

  const updateInflatedProducts = useRecoilCallback(
    ({ set }) =>
      (
        key: FlatSheetKey,
        tabIndex: number,
        nextProducts: InflatedProduct[]
      ) => {
        set(inflatedTabsFamily(key), (prevState) => {
          if (prevState && tabIndex > -1) {
            return produce(prevState, (draft) => {
              const draftTabIndex = draft.tabs.findIndex(
                (tab) => tab.index === tabIndex
              );

              if (draftTabIndex > -1) {
                nextProducts.forEach((nextProduct) => {
                  const productIndex = draft.tabs[
                    draftTabIndex
                  ].products.findIndex(
                    (product) => product.id === nextProduct.id
                  );
                  if (productIndex > -1) {
                    draft.tabs[draftTabIndex].products[productIndex] = {
                      ...nextProduct,
                      ...calcSupply(nextProduct),
                    };
                  }
                });
                draft.tabs[draftTabIndex].isDirty = true;
              }
            });
          }
          return prevState;
        });
      },
    []
  );

  const getInflatedProductById = useRecoilCallback(
    ({ snapshot }) =>
      (key: FlatSheetKey, tabIndex: number, id: number) => {
        const inflatedTabsLodable = snapshot.getLoadable(
          inflatedTabsFamily(key)
        );
        if (inflatedTabsLodable.state === "hasValue") {
          const inflatedTabsState = inflatedTabsLodable.contents;
          if (inflatedTabsState) {
            const tab = inflatedTabsState.tabs.find(
              (t) => t.index === tabIndex
            );
            if (tab) {
              return tab.products.find((p) => p.id === id);
            }
          }
        } else {
          throw {
            code: -1,
            status: "SORRY_MY_BAD",
            message: "I thought synchronous atomFamily always has value",
          };
        }
      },
    []
  );

  //여기서도 filter하고 useFetchAndSetLineSheetTab에서도 필터하는구낭..
  //filter작업이 두곳에서 일어나는구만... 합치는게 좋은데..
  const filterInflatedProducts = useRecoilCallback(
    ({ set }) =>
      (
        key: FlatSheetKey,
        tabIndex: number,
        filter: (product: SheetProduct) => boolean,
        isDirty: boolean
      ) => {
        set(inflatedTabsFamily(key), (prevState) => {
          if (prevState) {
            return produce(prevState, (draft) => {
              if (tabIndex !== -1) {
                const draftTabIndex = draft.tabs.findIndex(
                  (tab) => tab.index === tabIndex
                );

                if (draft.tabs[draftTabIndex]) {
                  draft.tabs[draftTabIndex].products = draft.tabs[
                    draftTabIndex
                  ].products.map((product) => {
                    return {
                      ...product,
                      isFiltered: filter(product),
                    };
                  });
                  // prevState에서 isDirty가 false로 초기화되서 들어옴.
                  // useFetchAndSetOrderSheetTabs 가 호출되어서 여기서 초기화된 이후 이곳에 진입하게 되어서 그런걸로 추정됨.
                  // 우선 저장/주문 버튼이 켜져있을 때 필터를 변경하면 저장버튼이 비활성화되는 문제를 해결하기 위해
                  // isDirty를 order-sheet-set-page.tsx에서 받아와서 업데이트 쳐주는 걸로 해결함.
                  // https://uno-trading.slack.com/archives/C064JSFQ0TV/p1735797374776269
                  draft.tabs[draftTabIndex].isDirty = isDirty;
                }
              }
            });
          }
          return prevState;
        });
      },
    []
  );

  const resetInflatedSheet = useRecoilCallback(
    ({ reset }) =>
      (key: FlatSheetKey) => {
        reset(inflatedTabsFamily(key));
      },
    []
  );

  return {
    updateInflatedProduct,
    updateInflatedProducts,
    getInflatedProductById,
    filterInflatedProducts,
    resetInflatedSheet,
  };
}
