import AppButton from "@/features/line-sheet-sets/app-button";
import useCreateOrderSheetRevision, {
  CreateOrderSheetRevisionResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-revision";
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  forwardRef,
} from "react";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import {
  DenseOrderSheet,
  OrderSheetProduct,
} from "@/features/order-sheets/order-sheet.type";
import useIdentity from "@/features/ui/hooks/use-identity";
import { useRecoilValue } from "recoil";
import { Flex } from "@chakra-ui/react";
import {
  FlatProduct,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppToolTip, { AppToolTipProps } from "@/features/ui/app-tooltip";
import { ColumnApi, GridApi } from "ag-grid-community";
import { CompanyType } from "../types";

interface OrderSheetSetSaveButtonProps {
  orderSheetSetId: number;
  orderSheet: DenseOrderSheet | undefined;
  revisionNumber: number;
  isOrderSheetEditing: boolean;
  /*
   * 리오더가 되는 경우 oss는 제출 상태, os는 주문서 작성 상태가 된다,
   * 모든 os의 제출 여부로 ordered여부 판단한다.
   * */
  isAllOrderSheetsOrdered: boolean;
  onSaveLoading: (isLoading: boolean) => void;
  isOrderSheetEditable: boolean;
  isConfirmedEditable: boolean;
  onUpdatingInflatedProductCompleted: (isCompleted: boolean) => void;
  onOrderSheetSetSaveButtonClicked: (isClicked: boolean) => void;
  onAfterSave: (response: CreateOrderSheetRevisionResponse) => void;
  gridRef: {
    current: {
      api: GridApi<FlatProduct>;
      columnApi: ColumnApi;
    } | null;
  };
}

export default forwardRef(function OrderSheetSetSaveButton(
  {
    orderSheetSetId,
    orderSheet,
    revisionNumber,
    isAllOrderSheetsOrdered,
    isOrderSheetEditing,
    isOrderSheetEditable,
    onSaveLoading,
    onAfterSave,
    isConfirmedEditable,
    onUpdatingInflatedProductCompleted,
    onOrderSheetSetSaveButtonClicked,
    gridRef,
  }: OrderSheetSetSaveButtonProps,
  ref: React.Ref<{ handleSaveOrderSheetSet: () => void }>
) {
  const isSaveLoadingRef = useRef<boolean>(false);
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company!!;
  }, [identity]);
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId,
      orderSheetId: orderSheet?.id || -1,
      revisionNumber: revisionNumber ? revisionNumber : -1,
    };
  }, [orderSheetSetId, orderSheet?.id, revisionNumber]);

  const inflatedTabs = useRecoilValue(inflatedTabsFamily(sheetKey));

  const {
    isLoading: isCreateOrderSheetRevisionLoading,
    error: createOrderSheetRevisionError,
    fire: fireCreateOrderSheetRevision,
    data: createOrderSheetRevisionData,
    clear: clearCreateOrderSheetRevisionData,
  } = useCreateOrderSheetRevision();

  const isOrderSheetDirty = useMemo(() => {
    if (inflatedTabs) {
      return inflatedTabs.tabs.find((i) => i.isDirty) !== undefined;
    }
    return false;
  }, [inflatedTabs]);

  const handleSave = useCallback(async () => {
    /* 편집 중이던 상태에서 클릭했다면 강제로 편집 모드를 종료하면서 변경된 데이터를 업데이트해두지만,
     * 편집 중이지 않은 상태였다면 이미 업데이트가 완료된 상태임.
     * 따라서 편집 중이지 않은 상태였다면 데이터 업데이트가 완료되었다고 판단하기 위해 상태값을 사용함.
     */
    if (!isOrderSheetEditing) {
      onUpdatingInflatedProductCompleted(true);
    }
    /* Editing 상태가 종료될 때 Save 버튼을 클릭해서 강제로 종료된 것인지, 사용자가 직접 종료한 것인지 구분하기 위해 사용
     * Save 버튼 클릭에 의한 종료라면 오더시트세트를 저장해야하기 때문
     */
    onOrderSheetSetSaveButtonClicked(true);
    /* 편집 중이던 상태에서 Save 버튼을 클릭하면 강제로 편집 모드를 종료함. 편집 모드가 종료되지 않으면 저장 시 편집 중이던 데이터가 저장되지 않을 수 있음.
     * AG Grid에서 Editing 종료 이벤트가 발생하면, OrderSheetTab에서 inflatedProduct를 업데이트하게 됨.
     * inflatedProduct가 업데이트된 이후에 저장을 진행해야 편집 중이던 데이터까지 저장할 수 있음.
     */
    gridRef?.current?.api.stopEditing();
  }, [
    onUpdatingInflatedProductCompleted,
    onOrderSheetSetSaveButtonClicked,
    gridRef,
    isOrderSheetEditing,
  ]);

  const handleSaveOrderSheetSet = () => {
    if (
      !isSaveLoadingRef.current &&
      orderSheet &&
      inflatedTabs &&
      identity &&
      identity.company
    ) {
      isSaveLoadingRef.current = true;
      const companyId = identity.company.id;
      fireCreateOrderSheetRevision(
        {
          by: identity.company.type,
          companyId: companyId,
          orderSheetSetId,
          orderSheetId: orderSheet.id,
        },
        {
          orderSheetTabs: inflatedTabs.tabs.map((tab) => {
            return {
              index: tab.index,
              products: tab.products.map((product): OrderSheetProduct => {
                return {
                  id: product.id,
                  priceAdjustedBy: product.priceAdjustedBy,
                  supplyPrice: product.supplyPrice,
                  firstOrderQuantityWithOptionList:
                    product.firstOrderQuantityWithOptionList,
                  prevOrderQuantityWithOptionList:
                    product.prevOrderQuantityWithOptionList,
                  latestOrderQuantityWithOptionList:
                    product.latestOrderQuantityWithOptionList,
                  confirmedQuantityWithOptionList:
                    identity.company?.type === "BUYER"
                      ? null
                      : product.confirmedQuantityWithOptionList,
                  orderQuantityWithOptionList:
                    product.orderQuantityWithOptionList,
                  newStockWithOptionList: product.newStockWithOptionList,
                  confirmedPrice: product.confirmedPrice,
                  confirmedPriceAdjustedBy: product.confirmedPriceAdjustedBy,
                };
              }),
            };
          }),
        },
        {
          rethrow: true,
        }
      )
        .catch((e) => {
          showError(e);
        })
        .finally(() => {
          isSaveLoadingRef.current = false;
          onOrderSheetSetSaveButtonClicked(false);
        });
    }
  };

  /* 상위 컴포넌트에서 미리 만들어서 넘겨준 ref에 본 컴포넌트의 함수를 할당함
   * 이렇게 하면 상위 컴포넌트에서 ref.current.handleSaveOrderSheetSet()로 호출할 수 있음
   * Save의 로직은 본 컴포넌트에서 정의하고,
   * 상위 컴포넌트에서 호출(하단 stopEditing 처리 후 save로직을 실행할 수 있도록)하기 위해 이와 같은 구조를 사용함
   */
  useImperativeHandle(ref, () => ({
    handleSaveOrderSheetSet,
  }));

  useEffect(() => {
    onSaveLoading(isCreateOrderSheetRevisionLoading);
  }, [isCreateOrderSheetRevisionLoading, onSaveLoading]);

  useEffect(() => {
    if (createOrderSheetRevisionData) {
      onAfterSave(createOrderSheetRevisionData);
    }
  }, [onAfterSave, createOrderSheetRevisionData]);

  const tooltipProps = useMemo(():
    | Pick<AppToolTipProps, "level" | "label">
    | undefined => {
    if (identity?.company?.type === "AGENCY" && isConfirmedEditable) {
      return undefined;
    }

    if (isAllOrderSheetsOrdered) {
      return {
        level: "SUCCESS",
        label: t("common:order.disabled.already_ordered"),
      };
    } else if (!isOrderSheetEditable) {
      return {
        level: "ERROR",
        label: t("save.disabled.not_state_of_editable"),
      };
    } else if (!isOrderSheetDirty) {
      return {
        level: "ERROR",
        label: t("save.disabled.no_change"),
      };
    }
  }, [
    isOrderSheetDirty,
    identity?.company?.type,
    isConfirmedEditable,
    isAllOrderSheetsOrdered,
    isOrderSheetEditable,
    t,
  ]);

  return (
    <AppToolTip
      level={tooltipProps?.level}
      hasArrow={true}
      label={tooltipProps?.label}
      isDisabled={tooltipProps === undefined}
      modifiers={[
        {
          name: "preventOverflow",
          options: {
            padding: 8,
          },
        },
      ]}
    >
      <Flex>
        <AppButton
          width={"80px"}
          isDisabled={tooltipProps !== undefined}
          isLoading={
            isCreateOrderSheetRevisionLoading || isSaveLoadingRef.current
          }
          onClick={handleSave}
        >
          {tTitle("save")}
        </AppButton>
      </Flex>
    </AppToolTip>
  );
});
