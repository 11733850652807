import useIdentity from "@/features/ui/hooks/use-identity";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import { useRecoilValue } from "recoil";
import {
  OrderSheet,
  OrderSheetProduct,
} from "@/features/order-sheets/order-sheet.type";
import { Flex } from "@chakra-ui/react";
import AppButton from "@/features/line-sheet-sets/app-button";
import useCreateOrderSheetRevision, {
  CreateOrderSheetRevisionResponse,
} from "@/features/line-sheet-sets/hooks/use-create-order-sheet-revision";
import useAppToasts from "@/features/line-sheet-sets/hooks/use-app-toasts";
import useDisclosureWithData from "@/features/order-sheet-sets/hooks/use-disclosure-with-data";
import { SubmitOrderSheetSetResponse } from "@/features/order-sheet-sets/hooks/use-submit-order-sheet-set";
import OrderOrderSheetSetModal, {
  OrderOrderSheetSetModalProps,
} from "@/features/order-sheet-sets/order-order-sheet-set-modal";
import {
  FlatProduct,
  inflatedTabsFamily,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import useI18nHelper from "@/features/ui/hooks/use-i18n-helper";
import AppToolTip, { AppToolTipProps } from "@/features/ui/app-tooltip";
import { ColumnApi, GridApi } from "ag-grid-community";
import TagManager from "react-gtm-module";

interface OrderSheetSetOrderButtonProps {
  isOrderSheetEditing: boolean;
  isOrderSheetSetOrderable: boolean;
  /*
   * 리오더가 되는 경우 oss는 제출 상태, os는 주문서 작성 상태가 된다,
   * 모든 os의 제출 여부로 ordered여부 판단한다.
   * */
  isAllOrderSheetsOrdered: boolean;
  orderSheetSet:
    | { id: number; orderSheets: { status: OrderSheet["status"] }[] }
    | undefined;
  orderSheet: { id: number } | undefined;
  revisionNumber?: number;
  onSave: (response: CreateOrderSheetRevisionResponse) => void;
  onUpdatingInflatedProductCompleted: (isCompleted: boolean) => void;
  onOrderSheetOrderButtonClicked: (isClicked: boolean) => void;
  onSubmit: (response: SubmitOrderSheetSetResponse) => void;
  gridRef: {
    current: {
      api: GridApi<FlatProduct>;
      columnApi: ColumnApi;
    } | null;
  };
}

export default forwardRef(function OrderSheetOrderButton(
  {
    isOrderSheetEditing,
    isOrderSheetSetOrderable,
    isAllOrderSheetsOrdered,
    orderSheetSet,
    orderSheet,
    revisionNumber,
    onSave,
    onUpdatingInflatedProductCompleted,
    onOrderSheetOrderButtonClicked,
    onSubmit,
    gridRef,
  }: OrderSheetSetOrderButtonProps,
  ref: React.Ref<{ handleOrderOrderSheet: () => void }>
) {
  const identity = useIdentity();
  const company = useMemo(() => {
    return identity?.company!!;
  }, [identity]);
  const { error: showError } = useAppToasts();
  const { t, tTitle } = useI18nHelper();

  const sheetKey = useMemo(() => {
    return {
      orderSheetSetId: orderSheetSet?.id || -1,
      orderSheetId: orderSheet?.id || -1,
      revisionNumber: revisionNumber || -1,
    };
  }, [orderSheetSet?.id, orderSheet?.id, revisionNumber]);

  const inflatedTabs = useRecoilValue(inflatedTabsFamily(sheetKey));

  const isOrderSheetDirty = useMemo(() => {
    if (inflatedTabs) {
      return inflatedTabs.tabs.find((i) => i.isDirty) !== undefined;
    }
    return false;
  }, [inflatedTabs]);

  const isAllOrderSheetInitialDraft = useMemo(() => {
    if (orderSheetSet) {
      return orderSheetSet.orderSheets.every((orderSheet) => {
        return orderSheet.status === "INITIAL_DRAFT";
      });
    }
    return true;
  }, [orderSheetSet]);

  const {
    isOpen: isOrderOrderSheetSetModalOpen,
    onOpen: onOpenOrderOrderSheetSetModal,
    onClose: onCloseOrderOrderSheetSetModal,
    data: orderOrderSheetSetModalData,
    openWithData: openOrderOrderSheetSetModalWithData,
  } = useDisclosureWithData<OrderOrderSheetSetModalProps>();

  const {
    isLoading: isCreateOrderSheetRevisionLoading,
    error: createOrderSheetRevisionError,
    fire: fireCreateOrderSheetRevision,
    data: createOrderSheetRevisionData,
    clear: clearCreateOrderSheetRevisionData,
  } = useCreateOrderSheetRevision();

  useEffect(() => {
    showError(createOrderSheetRevisionError);
  }, [showError, createOrderSheetRevisionError]);

  const tooltipProps = useMemo(():
    | Pick<AppToolTipProps, "level" | "label">
    | undefined => {
    if (isAllOrderSheetsOrdered) {
      return {
        level: "SUCCESS",
        label: t("common:order.disabled.already_ordered"),
      };
    } else if (!isOrderSheetSetOrderable) {
      return {
        level: "ERROR",
        label: t("common:order.disabled.not_state_of_being_ordered"),
      };
    } else if (!isOrderSheetDirty) {
      if (isAllOrderSheetInitialDraft) {
        return {
          level: "ERROR",
          label: t("common:order.disabled.no_change"),
        };
      }
    }
    return undefined;
  }, [
    isAllOrderSheetInitialDraft,
    isOrderSheetDirty,
    isOrderSheetSetOrderable,
    isAllOrderSheetsOrdered,
    t,
  ]);

  const openOrderOrderSheetSetModal = useCallback(() => {
    if (orderSheetSet && company.type === "BUYER") {
      openOrderOrderSheetSetModalWithData((prev) => {
        if (process.env.NEXT_PUBLIC_API_URL == "https://api.pathrade.com") {
          const tagManagerArgs = {
            dataLayer: {
              event: "orderButtonClick",
              page: "Order Sheet Tab",
              userId: identity?.id,
              companyId: identity?.company?.id,
              orderSheetSetId: orderSheetSet.id,
              orderSheetId: orderSheet?.id,
            },
            dataLayerName: "ClickDataLayer",
          };
          TagManager.dataLayer(tagManagerArgs);
          console.log("Reporting GTM click for order is done.");
        }

        return {
          orderSheetSetId: orderSheetSet.id,
          // 주문서 내에서는 해당 주문서만 주문 가능하도록 함
          orderSheetIds: orderSheet?.id ? [orderSheet.id] : [],
          onSubmit,
        };
      });
    }
  }, [
    orderSheetSet,
    company.type,
    openOrderOrderSheetSetModalWithData,
    orderSheet?.id,
    onSubmit,
    identity?.id,
    identity?.company?.id,
  ]);

  const handleOrderClick = useCallback(async () => {
    /* 편집 중이던 상태에서 클릭했다면 강제로 편집 모드를 종료하면서 변경된 데이터를 업데이트해두지만,
     * 편집 중이지 않은 상태였다면 이미 업데이트가 완료된 상태임.
     * 따라서 편집 중이지 않은 상태였다면 데이터 업데이트가 완료되었다고 판단하기 위해 상태값을 사용함.
     */
    if (!isOrderSheetEditing) {
      onUpdatingInflatedProductCompleted(true);
    }
    /* Editing 상태가 종료될 때 Save 버튼을 클릭해서 강제로 종료된 것인지, 사용자가 직접 종료한 것인지 구분하기 위해 사용
     * Save 버튼 클릭에 의한 종료라면 오더시트세트를 저장해야하기 때문
     */
    onOrderSheetOrderButtonClicked(true);
    /* 편집 중이던 상태에서 Save 버튼을 클릭하면 강제로 편집 모드를 종료함. 편집 모드가 종료되지 않으면 저장 시 편집 중이던 데이터가 저장되지 않을 수 있음.
     * AG Grid에서 Editing 종료 이벤트가 발생하면, OrderSheetTab에서 inflatedProduct를 업데이트하게 됨.
     * inflatedProduct가 업데이트된 이후에 저장을 진행해야 편집 중이던 데이터까지 저장할 수 있음.
     */
    gridRef?.current?.api.stopEditing();
  }, [
    onUpdatingInflatedProductCompleted,
    onOrderSheetOrderButtonClicked,
    gridRef,
    isOrderSheetEditing,
  ]);

  const handleOrderOrderSheet = useCallback(async () => {
    if (
      orderSheetSet &&
      inflatedTabs &&
      orderSheet &&
      company?.type === "BUYER"
    ) {
      if (isOrderSheetDirty) {
        const orderSheetTabs = inflatedTabs.tabs.map((tab) => {
          return {
            index: tab.index,
            products: tab.products.map((product): OrderSheetProduct => {
              return {
                id: product.id,
                priceAdjustedBy: product.priceAdjustedBy,
                supplyPrice: product.supplyPrice,
                firstOrderQuantityWithOptionList:
                  product.firstOrderQuantityWithOptionList,
                prevOrderQuantityWithOptionList:
                  product.prevOrderQuantityWithOptionList,
                latestOrderQuantityWithOptionList:
                  product.latestOrderQuantityWithOptionList,
                orderQuantityWithOptionList:
                  product.orderQuantityWithOptionList,
                newStockWithOptionList: product.newStockWithOptionList,
                confirmedPrice: product.confirmedPrice,
                confirmedPriceAdjustedBy: product.confirmedPriceAdjustedBy,
                confirmedQuantityWithOptionList:
                  product.confirmedQuantityWithOptionList,
              };
            }),
          };
        });
        if (orderSheetTabs) {
          fireCreateOrderSheetRevision(
            {
              by: company.type,
              companyId: company.id,
              orderSheetSetId: orderSheetSet.id,
              orderSheetId: orderSheet.id,
            },
            {
              orderSheetTabs,
            }
          )
            .catch((e) => {
              showError(e);
            })
            .finally(() => {
              onOrderSheetOrderButtonClicked(false);
            });
        }
      } else {
        openOrderOrderSheetSetModal();
      }
    }
  }, [
    orderSheetSet,
    orderSheet,
    isOrderSheetDirty,
    company,
    inflatedTabs,
    fireCreateOrderSheetRevision,
    openOrderOrderSheetSetModal,
    showError,
    onOrderSheetOrderButtonClicked,
  ]);

  /* 상위 컴포넌트에서 미리 만들어서 넘겨준 ref에 본 컴포넌트의 함수를 할당함
   * 이렇게 하면 상위 컴포넌트에서 ref.current.handleOrderOrderSheet()로 호출할 수 있음
   * Order의 로직은 본 컴포넌트에서 정의하고,
   * 상위 컴포넌트에서 호출(하단 stopEditing 처리 후 order로직을 실행할 수 있도록)하기 위해 이와 같은 구조를 사용함
   */
  useImperativeHandle(ref, () => ({
    handleOrderOrderSheet,
  }));

  useEffect(() => {
    if (createOrderSheetRevisionData) {
      onSave(createOrderSheetRevisionData);
      clearCreateOrderSheetRevisionData();
      openOrderOrderSheetSetModal();
    }
  }, [
    onSave,
    createOrderSheetRevisionData,
    openOrderOrderSheetSetModal,
    clearCreateOrderSheetRevisionData,
  ]);

  return (
    <>
      <AppToolTip
        level={tooltipProps?.level}
        hasArrow={true}
        label={tooltipProps?.label}
        isDisabled={tooltipProps === undefined}
        modifiers={[
          {
            name: "preventOverflow",
            options: {
              padding: 8,
            },
          },
        ]}
      >
        <Flex>
          <AppButton
            width={"80px"}
            variant={"primary"}
            isDisabled={tooltipProps !== undefined}
            isLoading={isCreateOrderSheetRevisionLoading}
            onClick={handleOrderClick}
          >
            {tTitle("order")}
          </AppButton>
        </Flex>
      </AppToolTip>
      {orderOrderSheetSetModalData && (
        <OrderOrderSheetSetModal
          {...orderOrderSheetSetModalData}
          isOpen={isOrderOrderSheetSetModalOpen}
          onClose={onCloseOrderOrderSheetSetModal}
        />
      )}
    </>
  );
});
