import { ICellRendererParams } from "ag-grid-community";
import {
  FlatProduct,
  isOverlappedValue,
  OverlappedValue,
} from "@/features/line-sheet-sets/helpers/sheet-state";
import { Flex, Grid, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { AppUtils } from "./utils/app-utils";

export interface OverlappedValueCellProps {
  unit?: string;
  isEditable?: boolean;
  topTextAlign?: "start" | "end" | "center";
}

export default function OverlappedValueCell<T>({
  api,
  unit,
  value,
  topTextAlign,
  isEditable = false,
}: OverlappedValueCellProps &
  ICellRendererParams<FlatProduct, OverlappedValue<T> | T>) {
  const [top, under, colorCode] = useMemo(() => {
    if (isOverlappedValue(value, (item) => item !== undefined)) {
      return [value.top, value.under, value.colorCode] as const;
    } else if (unit === "%" && value && typeof value === "number") {
      /**
       * unit이 %인 것은 컨디션이라는 의미임.
       * 컨디션인 경우 노출은 소수점 2자리까지만 하되 실제 값은 소수점을 모두 보관함.
       * 예를 들어 0.1234가 들어오면 12.34%로 노출하고, 실제 값은 0.1234로 보관함.
       * 이렇게 해주어야 supply price가 정확히 계산될 수 있기 때문.
       */
      return [AppUtils.trimFloat(value), undefined, undefined];
    } else {
      return [value, undefined, undefined];
    }
  }, [value, unit]);

  const isContentOverlapped = under !== undefined && top != under;
  const isContentDifferent = under !== undefined && top != under;

  if (top == 0 && under == 0 && unit != "%") {
    return;
  }

  let color = colorCode;
  if (!colorCode) {
    color = isContentDifferent ? "#CC1D1D" : undefined;
  }

  return (
    <Grid
      width={"100%"}
      gridTemplateColumns={isContentOverlapped ? "1fr 1fr" : "1fr"}
      alignItems={"center"}
      justifyItems={"center"}
      gridTemplateRows={"24px"}
      // padding={"0 4px"}
      gap={"4px"}
      // backgroundColor={"red.200"}
    >
      <Text
        justifySelf={
          topTextAlign ? topTextAlign : isContentOverlapped ? "end" : "center"
        }
        // justifySelf={"end"}
        color={color}
        textAlign={"right"}
        fontFamily={"Inter"}
        fontSize={"12px"}
        fontStyle={"normal"}
        fontWeight={400}
        lineHeight={"normal"}
      >
        {(top === -1 ? "∞" : top === 0 && unit === "-" ? "" : top) as string}
        {unit}
      </Text>

      {isContentOverlapped && (
        <Flex
          flexDirection={"row"}
          alignItems={"center"}
          color={"#8F8F8C"}
          justifySelf={"start"}
        >
          (
          <Text
            textAlign={"right"}
            fontFamily={"Inter"}
            fontSize={"12px"}
            fontStyle={"normal"}
            fontWeight={400}
            lineHeight={"normal"}
            textDecorationLine={"line-through"}
          >
            {(under === -1 ? "∞" : under) as string}
            {unit}
          </Text>
          )
        </Flex>
      )}
    </Grid>
  );
}
